import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebookF, faInstagram, faTiktok} from '@fortawesome/free-brands-svg-icons';
import logo from '../../source/artuvalleimg.png';
import s from './Footer.module.css';

export default function Footer(){
    return (
        <footer>
            <div className={s.footerContainer}>
                <div className={s.info}>
                    <h2>Dirección</h2>
                    <p>Lima - Perú</p>
                    <h2>Correo</h2>
                    <p>contacto@arturovallearquitectos.com</p>
                </div>
                <div className={s.socialmedia}>
                    <div className={s.wrapper}>
                        <a href="https://www.facebook.com/arturovalle.arquitectos">
                            <div className={`${s.icon} ${s.facebook}`}>
                                <div className={s.tooltip}>Facebook</div>
                                <span><FontAwesomeIcon className={s.i} icon={faFacebookF} /></span>
                            </div>
                        </a>

                        <a href="https://www.instagram.com/arturovalle.arquitectos/">
                            <div className={`${s.icon} ${s.instagram}`}>
                                <div className={s.tooltip}>Instagram</div>
                                <span><FontAwesomeIcon className={s.i} icon={faInstagram} /></span>
                            </div>
                        </a>
                        <a href="https://www.youtube.com/">
                            <div className={`${s.icon} ${s.tiktok}`}>
                                <div className={s.tooltip}>Tiktok</div>
                                <span><FontAwesomeIcon icon={faTiktok} className={s.i}/></span>
                            </div>
                        </a>
                    </div>
                    <p>© Copyright 2022 - AnthonySP</p>
                </div>
                <a className={s.logo} href='/'>
                    <img src={logo} alt="avarquitectos-logo" />
                    <p>© Copyright 2022 - AnthonySP</p>
                </a>
            </div>
            
    </footer>
    )
}