import React from "react";
import s from './Gallery.module.css';

function Gallery() {
    return(
        // Empieza la iformación
        <div className={s.gallery}>
            <div className={s.firtGroup}>
                <div className={s.galleryColumn}>
                    <a href="https://unsplash.com/@jeka_fe" className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/_cvwXhGqG-o/300x300" alt="P" className={s.galleryImage} />
                            <figcaption className={s.galleryCaption}>Portrait by Jessica Felicio</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@oladimeg" className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/AHBvAIVqk64/300x500" alt="P" className={s.galleryImage} />
                            <figcaption className={s.galleryCaption}>Portrait by Oladimeji Odunsi</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@a2eorigins" className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/VLPLo-GtrIE/300x300" alt="P" className={s.galleryImage} />
                            <figcaption className={s.galleryCaption}>Portrait by Alex Perez</figcaption>
                        </figure>
                    </a>
                </div>

                <div className={s.galleryColumn}>
                    <a href="https://unsplash.com/@noahbuscher" className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/AR7aumwKr2s/300x300" alt="P" className={s.galleryImage} />
                            <figcaption className={s.galleryCaption}>Portrait by Noah Buscher</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@von_co" className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/dnL6ZIpht2s/300x300" alt="P" className={s.galleryImage} />
                            <figcaption className={s.galleryCaption}>Portrait by Ivana Cajina</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@samburriss" className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/tV_1sC603zA/300x500" alt="Portrait by Sam Burriss" className={s.galleryImage} />
                            <figcaption className={s.galleryCaption}>Portrait by Sam Burriss</figcaption>
                        </figure>
                    </a>
                </div>

            </div>
            <div className={s.secondGroup}>
                <div className={s.galleryColumn}>
                    <a href="https://unsplash.com/@marilezhava"  className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/Xm9-vA_bhm0/300x500" alt="P" className={s.galleryImage}/>
                                <figcaption className={s.galleryCaption}>Portrait by Mari Lezhava</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@ethanhaddox"  className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/NTjSR3zYpsY/300x300" alt="P" className={s.galleryImage}/>
                                <figcaption className={s.galleryCaption}>Portrait by Ethan Haddox</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@mr_geshani"  className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/2JH8d3ChNec/300x300" alt="P" className={s.galleryImage}/>
                                <figcaption className={s.galleryCaption}>Portrait by Amir Geshani</figcaption>
                        </figure>
                    </a>
                </div>

                <div className={s.galleryColumn}>
                    <a href="https://unsplash.com/@frxgui"  className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/FQhLLehm4dk/300x300" alt="P" className={s.galleryImage}/>
                                <figcaption className={s.galleryCaption}>Portrait by Guilian Fremaux</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@majestical_jasmin"  className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/OQd9zONSx7s/300x300" alt="P" className={s.galleryImage}/>
                                <figcaption className={s.galleryCaption}>Portrait by Jasmin Chew</figcaption>
                        </figure>
                    </a>

                    <a href="https://unsplash.com/@dimadallacqua"  className={s.galleryLink}>
                        <figure className={s.galleryThumb}>
                            <img src="https://source.unsplash.com/XZkEhowjx8k/300x500" alt="P" className={s.galleryImage}/>
                                <figcaption className={s.galleryCaption}>Portrait by Dima DallAcqua</figcaption>
                        </figure>
                    </a>
                </div>
            </div>
        </div>
        //Termina la información
    )
}

export default Gallery;
