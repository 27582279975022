import { Route, Switch } from "react-router-dom";
import React from 'react';
import Footer from './components/footer/Footer';
import NavBar from './components/navbar/Navbar';
import BodyInfo from './components/bodyinfo/BodyInfo';
import Nosotros from "./components/bodyinfo/nosotros/Nosotros";
import Contacto from "./components/bodyinfo/contacto/Contacto";
import NavBarPage from "./components/navbar/NavbarPage";
import Proyectos from "./components/bodyinfo/proyectos/Proyectos";
import Trabajos from "./components/bodyinfo/trabajos/Trabajos";
import './App.css';




function App() {
  return (
    <div className="App">
        
        <Switch>
          <Route exact path='/'>
            <NavBar/>
            <BodyInfo/>
          </Route>
          <Route path='/Proyectos'>
            <NavBarPage/>
            <Proyectos/>
          </Route>
          <Route path='/Trabajos'>
            <NavBarPage/>
            <Trabajos/>
          </Route>
          <Route path='/Contacto'>
            <NavBarPage/>
            <Contacto/>
          </Route>
          <Route path='/Nosotros'>
            <NavBarPage/>
            <Nosotros/>
          </Route>
        </Switch>
        <Footer/>
        
    </div>
  );
}

export default App;
