import React from "react";
import s from './Nosotros.module.css'

export default function Nosotros() {
    return (
        <><div className={s.textoConteiner}>
            <h1 className={s.title}>Nuestro Equipo</h1>
            <p className={s.descripcionProyectos}>"Nuestros prroyectos buscan bla bla bla para asi poder bla bla bla y de esa manera bla bla nos enfocamos en bla bla. Con nuestros socios bla bla se logro identificar bla bla nuevas tendencias bla bla y fin."</p>
        </div><div className={s.movieConteiner}>
                <div className={s.movieConteiner}></div>
                {/* FIRST CARD */}
                <div className={s.movieCard} id={s["bright"]}>
                    <div className={s.infoSection}>
                        <div className={s.movieHeader}>
                            <img className={s.locandina} src="https://movieplayer.net-cdn.it/t/images/2017/12/20/bright_jpg_191x283_crop_q85.jpg" alt="imagen1" />
                            <h1>Bright</h1>
                            <h4>2017, David Ayer</h4>
                            <span className={s.minutes}>117 min</span>
                            <p className={s.type}>Action, Crime, Fantasy</p>
                        </div>
                        <div className={s.movieDesc}>
                            <p className={s.text}>
                                Set in a world where fantasy creatures live side by side with humans. A human cop is forced to work with an Orc to find a weapon everyone is prepared to kill for.
                            </p>
                        </div>
                    </div>
                    <div className={`${s.blurBack} ${s.brightBack}`}></div>
                </div>

                {/* SECOND CARD */}

                <div className={s.movieCard} id={s["tomb"]}>
                    <div className={s.infoSection}>
                        <div className={s.movieHeader}>
                            <img className={s.locandina} src="https://mr.comingsoon.it/imgdb/locandine/235x336/53750.jpg" alt="imagen1"/>
                            <h1>Tomb Raider</h1>
                            <h4>2018, Roar Uthaug</h4>
                            <span className={s.minutes}>125 min</span>
                            <p className={s.type}>Action, Fantasy</p>
                        </div>
                        <div className={s.movieDesc}>
                            <p className={s.text}>
                                Lara Croft, the fiercely independent daughter of a missing adventurer, must push herself beyond her limits when she finds herself on the island where her father disappeared.
                            </p>
                        </div>
                    </div>
                    <div className={`${s.blurBack} ${s.tombBack}`}></div>
                </div>

                {/* THIRD CARD */}

                <div className={s.movieCard} id={s["ave"]}>
                    <div className={s.infoSection}>
                        <div className={s.movieHeader}>
                            <img className={s.locandina} src="https://mr.comingsoon.it/imgdb/locandine/235x336/53715.jpg" alt="imagen1"/>
                            <h1>Black Panther</h1>
                            <h4>2018, Ryan Coogler</h4>
                            <span className={s.minutes}>134 min</span>
                            <p className={s.type}>Action, Adventure, Sci-Fi</p>
                        </div>
                        <div className={s.movieDesc}>
                            <p className={s.text}>
                                T'Challa, the King of Wakanda, rises to the throne in the isolated, technologically advanced African nation, but his claim is challenged by a vengeful outsider who was a childhood victim of T'Challa's father's mistake.
                            </p>
                        </div>
                    </div>
                    <div className={`${s.blurBack} ${s.aveBack}`}></div>
                </div>
            </div></>
    )
}