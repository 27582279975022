import React from "react";
import Gallery from "./Gallery";
import Customers from "./Customers";
import s from './BodyInfo.css'

export default function BodyInfo() {
    return (
        <div className={s.bodyInfo}>
            <h1 className={s.title}>Nuestros Proyectos</h1>
            <Gallery />
            <h1 className={s.title}>Nuestros Clientes</h1>
            <Customers/>
        </div>
    )
}