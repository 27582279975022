import React from "react";
import s from './Customers.module.css';

export default function Customers() {

    return (
        <div className={s.container}>
            <div className={s.card}>
                <div className={s.cardHeader}>
                    <img src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg" alt="rover" />
                </div>
                <div className={s.cardBody}>
                    <span className={`${s.tag} ${s.tagTeal}`}>ACABADOS</span>
                    <h4>
                        Parque tematico para la marina
                    </h4>
                    <p>
                        Informacion extra Informacion extra Informacion extra Informacion extra Informacion extra Informacion 
                    </p>
                    <div className={s.user}>
                        <img src="https://yt3.ggpht.com/a/AGF-l7-0J1G0Ue0mcZMw-99kMeVuBmRxiPjyvIYONg=s900-c-k-c0xffffffff-no-rj-mo" alt="user" />
                        <div className={s.userInfo}>
                            <h5>Marina de guerra del Perú</h5>
                            <small>28 de Agosto 2022</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.card}>
                <div className={s.cardHeader}>
                    <img src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg" alt="rover" />
                </div>
                <div className={s.cardBody}>
                    <span className={`${s.tag} ${s.tagPurple}`}>CONSTRUCCIÓN</span>
                    <h4>
                        Edificio Mirasol y Miraluna
                    </h4>
                    <p>
                        Informacion extra Informacion extra Informacion extra Informacion extra Informacion extra Informacion
                    </p>
                    <div className={s.user}>
                        <img src="https://yt3.ggpht.com/a/AGF-l7-0J1G0Ue0mcZMw-99kMeVuBmRxiPjyvIYONg=s900-c-k-c0xffffffff-no-rj-mo" alt="user" />
                        <div className={s.userInfo}>
                            <h5>Municipalidad Pisco</h5>
                            <small>18 Abril 2021</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.card}>
                <div className={s.cardHeader}>
                    <img src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg" alt="rover" />
                </div>
                <div className={s.cardBody}>
                    <span className={`${s.tag} ${s.tagPink}`}>DISEÑO INTERIOR</span>
                    <h4>
                        Salon para restaurante
                    </h4>
                    <p>
                        Informacion extra Informacion extra Informacion extra Informacion extra Informacion extra Informacion
                    </p>
                    <div className={s.user}>
                        <img src="https://yt3.ggpht.com/a/AGF-l7-0J1G0Ue0mcZMw-99kMeVuBmRxiPjyvIYONg=s900-c-k-c0xffffffff-no-rj-mo" alt="user" />
                        <div className={s.userInfo}>
                            <h5>Restaurante "El Cantarito"</h5>
                            <small>20 de diciembre 2020</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}