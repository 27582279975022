import React from "react";
import logo from '../../source/artuvalleimg.png';
import s from './NavbarPage.module.css';
import { Link } from "react-router-dom";

export default function NavBarPage() {

    return (
        <nav>
            <div className={s.navContainer}>
                <Link to='/'><img src={logo} alt='logo-avarquitectos' /></Link>
                <div class={s.respmenu}>
                    <ul className={s.menuBar}>
                        <Link to='/proyectos'><li className={s.list}>PROYECTOS</li></Link>
                        <Link to='/trabajos'><li className={s.list}>TRABAJOS</li></Link>
                        <Link to='/nosotros'><li className={s.list}>NOSOTROS</li></Link>
                        <Link to='/contacto'><li className={s.list}>CONTACTO</li></Link>
                    </ul>
                </div>
            </div>
        </nav>
    )
}