import React from "react";
import Gallery from "../Gallery";
import s from './Proyectos.module.css'

export default function Proyectos() {
    return(
        <div className={s.proyectosContainer}>
            <h1 className={s.title}>Nuestros Proyectos</h1>
            <p className={s.descripcionProyectos}>"Nuestros prroyectos buscan bla bla bla para asi poder bla bla bla y de esa manera bla bla nos enfocamos en bla bla. Con nuestros socios bla bla se logro identificar bla bla nuevas tendencias bla bla y fin."</p>
            <Gallery/>
        </div>
        
    )
}