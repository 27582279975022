import React from "react";
import s from './Slidenavbar.module.css';

export default function Slidenavbar() {
    return (
        <div>
            <div className={s.wrapper}>
                <div className={s.slideshows}>
                    <div className={ `${s.slideshow} ${s.slideshowHero}`}>
                        <div className={s.slides}>
                        <div className={s.navbar}>
                        </div>
                            <div className={`${s.slide} ${s.slide1}`}></div>
                            <div className={`${s.slide} ${s.slide2}`}></div>
                            <div className={`${s.slide} ${s.slide3}`}></div>
                        </div>
                    </div>
                    <div className={` ${s.slideshow} ${s.slideshowContrast} ${s.slideshowContrastBefore}`}>
                        <div className={s.slides}>
                            <div className={`${s.slide} ${s.slide1}`}></div>
                            <div className={`${s.slide} ${s.slide2}`}></div>
                            <div className={`${s.slide} ${s.slide3}`}></div>
                        </div>
                    </div>
                    <div className={` ${s.slideshow} ${s.slideshowContrast} ${s.slideshowContrastAfter}`}>
                        <div className={s.slides}>
                            <div className={`${s.slide} ${s.slide1}`}></div>
                            <div className={`${s.slide} ${s.slide2}`}></div>
                            <div className={`${s.slide} ${s.slide3}`}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}